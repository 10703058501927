// import React from 'react';
import { graphql } from 'gatsby';
import SEO from '@components/Common/SEO/index';
import '@fortawesome/fontawesome-svg-core/styles.css';
import React from 'react';
import CrackedCrioCareersSection from '@components/v5/CrackedCrioCareersSection/index';
import FAQSection from '@components/v5/FAQSection/index';
import PostContentCTALayout from '@components/v5/layouts/PostContentCTALayout/index';
import { Link } from 'gatsby';
import { EProgramIDs } from '@src/constants/data/programs/index';
import { PPCFullStackStylePageHero } from '@components/v5/FspStylePageHero/index';
import StatsPanelFSP from '@components/v5/StatsPanelFSP/index';
import FspCurriculumSection from '@components/v5/FspCurriculumSection/index';
import FspPricingCardSection from '@components/v5/FspPricingCardSection/index';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import {
  LEAD_GEN_FLOATING_CTA,
  LEAD_GEN_DEVELOPERS_CRACKED,
  LEAD_GEN_CURRICULUM_PRICING_CARD,
  LEAD_GEN_NAVIGATION,
  LEAD_GEN_TESTIMONIALS,
} from '../constants/LeadGenerationConstants/index';
import TechnologiesMasterSection from '@components/v5/TechnologiesMasterSection/index';
import fsp from '../seo/jsonld/fsd.json';
import fdt from '../seo/jsonld/fdt.json';
import bdt from '../seo/jsonld/bdt.json';
import JobGuaranteeCard from '@components/v5/JobGuaranteeCard/index';
import { StaticImage } from 'gatsby-plugin-image';
import RoundedLayout from '@components/v5/layouts/RoundedLayout/index';
import PricingCardSection from '@components/v5/PricingCardSection/index';
import Loader from '@components/Loader/index';
import TopStrip from '@components/PlacementReportTopStrip/index';
import { EPpcRoutes } from '@src/constants/routes/index';
import VideoTestimonialSection from '@components/v5/VideoTestimonialSection/index';
import useCrioStat from '@src/hooks/useCrioStats';

export default function FspStylePage(props) {
  const { data: queryData } = props;
  const { ppcFullStackStylePagesYaml: data } = queryData;
  const program = props.data.allStrapiPrograms.nodes.find(
    (ele) => ele.PrimaryProgramID === data.PrimaryProgramID,
  );

  const { placementStats } = useCrioStat();

  const programId = program?.PrimaryProgramID;
  const jsonLd =
    programId === EProgramIDs.FULL_STACK
      ? fdt
      : programId === EProgramIDs.FELLOWSHIP
      ? fsp
      : bdt;

  const sale = props.data.strapiSale.SaleID;

  const isDiwaliPage = data?.pageType === 'DIWALI';

  if (isDiwaliPage) {
    window.open('/404', '_self');

    return (
      <div className="flex h-full  w-full items-center justify-center">
        <Loader></Loader>
      </div>
    );
  }

  //Disable redirect to home page when Crio logo is clicked on ppc-ads-vl page
  //ppc-ads-vl page is copy of ppc-ads page for lead generation partner: "Value Leaf"
  const isFullStackPpcVl =
    data?.slug === `/${EPpcRoutes.FULL_STACK_PPC_ADS_VL}`;

  return (
    <div>
      <SEO
        title={data.SEOTitle}
        description={data.SEODescription}
        canonicalUrl={data.canonicalUrl}
        meta={data.metatags || []}
      >
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </SEO>
      <div className="fixed bottom-0 z-[1000] w-screen p-2 md:hidden">
        <LeadGenerationButton
          type="Apply & Start Free"
          buttonLocation={LEAD_GEN_FLOATING_CTA}
          className="h-11 w-full"
          isFullStackPpcVl={isFullStackPpcVl}
        />
      </div>

      <div className="fixed z-[1000]">
        <TopStrip />
        <nav className="z-[1000] flex w-screen justify-between bg-v5-green-500 p-3 px-4 md:fixed md:py-4 md:px-8">
          {/* For ppc-ads-vl, disable the link redirection to home page: */}
          {isFullStackPpcVl ? (
            <div>
              <StaticImage
                loading="eager"
                src="../../images/Crio_Light.png"
                placeholder="none"
                alt="Crio.Do"
                className="h-[35px] w-[100px] md:w-[130px]"
                imgStyle={{ objectFit: 'contain' }}
              />
            </div>
          ) : (
            <Link to="/">
              <div>
                <StaticImage
                  loading="eager"
                  src="../../images/Crio_Light.png"
                  placeholder="none"
                  alt="Crio.Do"
                  className="h-[35px] w-[100px] md:w-[130px]"
                  imgStyle={{ objectFit: 'contain' }}
                />
              </div>
            </Link>
          )}

          <LeadGenerationButton
            buttonLocation={LEAD_GEN_NAVIGATION}
            type="Navigation"
            className="hidden md:flex"
            isFullStackPpcVl={isFullStackPpcVl}
          />
        </nav>
      </div>

      <div className="bg-v5-green-700">
        <div className="text-v5-neutral-300">
          <PPCFullStackStylePageHero
            program={program}
            title={data.heroTitle}
            image={data.heroImage.childImageSharp}
            description={data.heroDescription}
            subTitle={data.heroSubtitle}
            statsSubtitle={data.statsSubtitle}
            sale={sale}
            showOpenForm
            className="!pt-10 pb-16"
          ></PPCFullStackStylePageHero>
        </div>

        {data?.slug === `/${EPpcRoutes.FULL_STACK_PPC_ADS}` ||
        data?.slug === `/${EPpcRoutes.FULL_STACK_PPC_ADS_VL}/` ? (
          <>
            <CrackedCrioCareersSection
              dark
              postContent={
                <PostContentCTALayout>
                  <div>
                    <div className="-mb-10">
                      <StatsPanelFSP
                        stats={placementStats.map((stat) => {
                          return {
                            title: stat.content,
                            subtitle: `${stat.label}`,
                          };
                        })}
                        dark
                      ></StatsPanelFSP>
                    </div>
                    <PostContentCTALayout className="mb-10">
                      <LeadGenerationButton
                        type="Apply & Start Free"
                        className="h-[64px] w-[300px]"
                        buttonLocation={LEAD_GEN_DEVELOPERS_CRACKED}
                      />
                    </PostContentCTALayout>
                  </div>
                </PostContentCTALayout>
              }
            ></CrackedCrioCareersSection>

            {data.technologies ? (
              <TechnologiesMasterSection
                data={data.technologies}
                program={program}
                primaryProgramID={program.PrimaryProgramID}
                postContent={
                  <PostContentCTALayout>
                    <LeadGenerationButton
                      type="Apply & Start Free"
                      buttonLocation={'LEAD_GEN_TECHNOLOGIES'}
                      isFullStackPpcVl={isFullStackPpcVl}
                    />
                  </PostContentCTALayout>
                }
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            {data.technologies ? (
              <TechnologiesMasterSection
                data={data.technologies}
                program={program}
                primaryProgramID={program.PrimaryProgramID}
                postContent={
                  <PostContentCTALayout>
                    <LeadGenerationButton
                      type="Apply & Start Free"
                      buttonLocation={'LEAD_GEN_TECHNOLOGIES'}
                      isFullStackPpcVl={isFullStackPpcVl}
                    />
                  </PostContentCTALayout>
                }
              />
            ) : (
              <></>
            )}

            <CrackedCrioCareersSection
              dark
              postContent={
                <PostContentCTALayout>
                  <div>
                    <div className="-mb-10">
                      <StatsPanelFSP
                        stats={placementStats.map((stat) => {
                          return {
                            title: stat.content,
                            subtitle: `${stat.label}`,
                          };
                        })}
                        dark
                      ></StatsPanelFSP>
                    </div>
                    <PostContentCTALayout className="mb-10">
                      <LeadGenerationButton
                        type="Apply & Start Free"
                        className="h-[64px] w-[300px]"
                        buttonLocation={LEAD_GEN_DEVELOPERS_CRACKED}
                        isFullStackPpcVl={isFullStackPpcVl}
                      />
                    </PostContentCTALayout>
                  </div>
                </PostContentCTALayout>
              }
            ></CrackedCrioCareersSection>
          </>
        )}

        <FspCurriculumSection
          dark
          quote={data.curriculumQuote}
          type={data.PrimaryProgramID}
          curriculum={data.curriculum}
          advancedCurriculum={data.advancedCurriculum}
          dreamCompanies={data.dreamCompanies}
          superDreamCompanies={data.superDreamCompanies}
          hideHeader
        ></FspCurriculumSection>
        <VideoTestimonialSection
          dark
          quote={data.testimonialQuote}
          disableCareerHandbook={
            data.PrimaryProgramID === EProgramIDs.QA_AUTOMATION_TESTING
          }
          postContent={
            <PostContentCTALayout>
              <div className="-mt-16 xxs:-mt-10 sm:-mt-0">
                <LeadGenerationButton
                  type="Apply & Start Free"
                  buttonLocation={LEAD_GEN_TESTIMONIALS}
                />
              </div>
            </PostContentCTALayout>
          }
        ></VideoTestimonialSection>

        {program.PrimaryProgramID === 'QA_AUTOMATION_TESTING' ? (
          <PricingCardSection
            dark
            price={data.price}
            duration={data.priceDuration}
            sale={sale}
            program={program.PrimaryProgramID}
            postContent={
              <PostContentCTALayout>
                <LeadGenerationButton
                  type="Apply & Start Free"
                  buttonLocation={LEAD_GEN_CURRICULUM_PRICING_CARD}
                  isFullStackPpcVl={isFullStackPpcVl}
                />
              </PostContentCTALayout>
            }
          ></PricingCardSection>
        ) : (
          <FspPricingCardSection
            dark
            price={data.price}
            duration={data.priceDuration}
            sale={sale}
            program={program.PrimaryProgramID}
            postContent={
              <PostContentCTALayout>
                <LeadGenerationButton
                  type="Apply & Start Free"
                  buttonLocation={LEAD_GEN_CURRICULUM_PRICING_CARD}
                  isFullStackPpcVl={isFullStackPpcVl}
                />
              </PostContentCTALayout>
            }
          ></FspPricingCardSection>
        )}
        <RoundedLayout
          roundedBg="bg-white"
          className="bg-v5-green-700 pb-6 text-black"
          bottom
        >
          <JobGuaranteeCard data={data.jobGuarantee} />
        </RoundedLayout>
      </div>

      <FAQSection data={data.faqs}></FAQSection>
    </div>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    ppcFullStackStylePagesYaml(id: { eq: $id }) {
      PrimaryProgramID
      slug
      SEOTitle
      canonicalUrl
      SEODescription

      metatags {
        property
        content
      }

      heroTitle
      heroSubtitle
      heroDescription
      heroImage {
        childImageSharp {
          gatsbyImageData(
            width: 750
            quality: 100
            blurredOptions: {}
            placeholder: NONE
            formats: [AUTO, WEBP]
          )
        }
      }

      statsSubtitle

      highlightsTitle
      highlights {
        description
        image {
          childImageSharp {
            gatsbyImageData(
              width: 200
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      projectsTitle
      projectsDescription

      careerTitle
      careerDescription
      careers {
        description
        image {
          childImageSharp {
            gatsbyImageData(
              width: 200
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      curriculumTitle
      curriculumDescription
      curriculumQuote

      technologies {
        name
        image {
          childImageSharp {
            gatsbyImageData(
              width: 200
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      dreamCompanies {
        image {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        originalImage {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      superDreamCompanies {
        image {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        originalImage {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      curriculum {
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 100
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        duration
        tag
        data
        skills
        anchor
        cardTitle
        cards {
          image {
            childImageSharp {
              gatsbyImageData(
                width: 300
                quality: 100
                blurredOptions: {}
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
          data
          skills
          title
        }
      }
      advancedCurriculum {
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 100
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        duration
        tag
        data
        cardTitle
        cards {
          image {
            childImageSharp {
              gatsbyImageData(
                width: 300
                quality: 100
                blurredOptions: {}
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
          data
          skills
          title
        }
      }

      steps

      price
      priceDuration

      faqs {
        question
        answer
      }

      jobGuarantee {
        question
        answer
        image {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }

    allStrapiPrograms {
      ...allStrapiProgramsFragment
    }

    ...strapiDataFragment
  }
`;
